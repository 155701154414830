import { LinkPanelTypes } from '../panels/settings-panel/constants'

export enum SuccessActionTypes {
  SHOW_MESSAGE = 'SHOW_MESSAGE',
  LINK = 'LINK',
  DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
}

export enum UploadStatuses {
  UPLOAD_SUCCESS = 'UPLOAD_SUCCESS',
  UPLOAD_FAILED = 'UPLOAD_FAILED',
  IN_PROCESS = 'IN_PROCESS',
  NONE = 'NONE',
}

export enum SecondsToResetDefaults {
  MIN = 3,
  MAX = 10,
}

export const VISIBLE_LINK_PANEL_SECTIONS_OLD = {
  PageLink: true,
  AnchorLink: false,
  NoLink: true,
  PhoneLink: false,
  ExternalLink: false,
  EmailLink: false,
  DocumentLink: false,
  LoginToWixLink: false,
  EdgeAnchorLinks: false,
  PopupLink: true,
}

export const VISIBLE_LINK_PANEL_SECTIONS = {
  PageLink: true,
  AnchorLink: false,
  NoLink: true,
  PhoneLink: false,
  ExternalLink: true,
  EmailLink: false,
  DocumentLink: false,
  LoginToWixLink: false,
  EdgeAnchorLinks: false,
  PopupLink: true,
}

export const DEFAULT_LINK_REQUEST = {
  value:
    {type : LinkPanelTypes.PAGE},
  visibleSections: VISIBLE_LINK_PANEL_SECTIONS
}
