import 'whatwg-fetch'

const MAX_FETCH_RETRIES = 3
const DELAY_AFTER_FETCH_RETRY = 100

export const fetchRetry = (url?: Request | string, options?: RequestInit) => {
  return new Promise((resolve, reject) => {
    const wrappedFetch = retries => {
      fetch(url, options)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          if (retries > 0) {
            retry(retries)
          } else {
            reject(error)
          }
        })
    }

    const retry = retries => {
      setTimeout(() => {
        wrappedFetch(--retries)
      }, DELAY_AFTER_FETCH_RETRY)
    }

    wrappedFetch(MAX_FETCH_RETRIES)
  })
}
