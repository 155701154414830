import { ROLE_SUBMIT_BUTTON } from '../constants/roles'
import { isCheckbox, isNumber, isRatingsInput, isUploadButton } from './viewer-utils'
import * as _ from 'lodash'
import { SUBMISSION_DISPLAY_FIELD } from '../constants/wixcode'
import { getInputValue } from './input-value'

export const ErrorTypeKey = 'wixFormsSubmitErrorType'

const withCatch = (func: Function, type: string) => {
  try {
    return func()
  } catch (err) {
    err[ErrorTypeKey] = type
    throw err
  }
}

const withCatchAsync = async (func: Function, type: string) => {
  try {
    return await func()
  } catch (err) {
    err[ErrorTypeKey] = type
    throw err
  }
}

export const DEFAULT_SUBMIT_ERROR = 'unknown client error'

export const SUBMIT_ERROR_TYPES = {
  GET_ATTACHMENTS: 'get attachments',
  VALIDATE_FIELDS: 'validate fields',
  GET_FIELDS: 'get fields',
  GET_COLLECTION_FIELDS: 'get collection fields',
  SEND_TO_SERVER: 'send to server',
  SEND_TO_WIX_DATA: 'send to wix data',
  GET_SUBMIT_BUTTON: 'get submit button',
  RESET_FIELDS: 'reset fields',
}

export const KNOWN_SUBMIT_ERROR_TYPES = Object.keys(SUBMIT_ERROR_TYPES)

export const getSubmitErrorType = error => error[ErrorTypeKey] || DEFAULT_SUBMIT_ERROR

export const getSubmitButton = ($w, throwException = true) =>
  withCatch(() => {
    const $submitButton = $w(`@${ROLE_SUBMIT_BUTTON}`)[0]
    if ($submitButton) {
      return $submitButton
    }

    if (throwException) {
      throw new Error('cannot find submit button')
    } else {
      return null
    }
  }, SUBMIT_ERROR_TYPES.GET_SUBMIT_BUTTON)

export const getFields = ({ $w, roles }) =>
  withCatch(() => {
    const fields = roles.reduce((res, roleField) => res.concat($w(`@${roleField}`)), [])
    return _.uniqBy(fields, (field: { uniqueId: string }) => field.uniqueId)
  }, SUBMIT_ERROR_TYPES.GET_FIELDS)

export const validateFields = ({ fields, strategy }) =>
  withCatch(() => {
    fields.forEach(field => field.updateValidityIndication && field.updateValidityIndication())
    return strategy.validateFields(fields)
  }, SUBMIT_ERROR_TYPES.VALIDATE_FIELDS)

export const getAttachments = fields =>
  withCatchAsync(async () => {
    return Promise.all(
      fields
        .filter(field => isUploadButton(field) && field.value.length > 0)
        .map(async field => {
          const { url } = await field.startUpload()
          return {
            url,
            name: field.value[0].name,
          }
        })
    )
  }, SUBMIT_ERROR_TYPES.GET_ATTACHMENTS)

export const sendFieldsToServer = async ({ strategy, attachments, fields }) =>
  withCatchAsync(async () => {
    return await strategy.execute({ attachments, fields })
  }, SUBMIT_ERROR_TYPES.SEND_TO_SERVER)

export const getCollectionFields = ({ fields, attachments }) =>
  withCatch(() => {
    const toInsert = {
      [SUBMISSION_DISPLAY_FIELD]: new Date(),
    }

    fields.forEach(field => {
      if (!_.get(field, 'connectionConfig.collectionFieldKey')) {
        return
      }

      let value = getInputValue(field, attachments)
      if (isUploadButton(field)) {
        value = _.get(value, 'url')
      }

      if (isCheckbox(field)) {
        value = value ? 'V' : ''
      }

      if (isNumber(field) || isRatingsInput(field)) {
        value = Number(value)
      }

      toInsert[field.connectionConfig.collectionFieldKey] = value
    })

    return toInsert
  }, SUBMIT_ERROR_TYPES.GET_COLLECTION_FIELDS)

export const sendFieldsToWixData = async ({ wixData, collectionId, fieldsToInsert }) =>
  withCatchAsync(async () => {
    await wixData.insert(collectionId, fieldsToInsert)
  }, SUBMIT_ERROR_TYPES.SEND_TO_WIX_DATA)

export const resetFields = fields =>
  withCatch(() => {
    fields.forEach(field => {
      if (isUploadButton(field)) {
        field.reset()
        return
      }

      if (isCheckbox(field)) {
        field.checked = false
      } else {
        field.value = null
      }

      if ('resetValidityIndication' in field) {
        field.resetValidityIndication()
      }
    })
  }, SUBMIT_ERROR_TYPES.RESET_FIELDS)
