import 'whatwg-fetch'
import Experiments from '@wix/wix-experiments'
import { ROLE_MESSAGE, ROLE_FORM, FIELDS_ROLES } from '../constants/roles'
import { initBiLogger } from '../utils/bi'
import { getSentryDSN, getAppVersion, serializeError } from '../utils/utils'
import { EVENTS } from '../constants/bi'
import * as _ from 'lodash'
import * as Raven from 'raven-js'
import { isUploadButton, escapeRegExp, innerText } from './viewer-utils'
import { createWixData } from './wix-data'
import { strategies } from './strategy/strategies'
import {
  getSubmitButton,
  validateFields,
  getAttachments,
  sendFieldsToServer,
  getCollectionFields,
  sendFieldsToWixData,
  getFields,
  resetFields,
  getSubmitErrorType,
} from './submit-utils'

const ERROR_COLOR = '#FF4040'
const ERRORS = {
  FILE_UPLOAD: 'File could not be uploaded. Try again or choose a different file.',
  SUBMISSION:
    'Sorry, something went wrong and the form was not submitted. Try again now or later if the problem persists.',
}

const viewerEvents = EVENTS.VIEWER_APP

Raven.config(getSentryDSN(), {
  logger: 'logger-viewer-app',
  release: getAppVersion(),
})

let initInstance
let biLogger: any = {}
let linksUtil
let wixData
let viewerAppUrl
let resolveExperiments
const experimentsPromise: Promise<Experiments> = new Promise(resolve => {
  resolveExperiments = resolve
})
const getExperiments = () => experimentsPromise

const getErrorModalUrl = msg =>
  viewerAppUrl &&
  viewerAppUrl
    .split('/')
    .slice(0, -1)
    .concat(['statics', `viewer-modal-panel.html?msg=${msg}`])
    .join('/')

const displayErrorModal = async ({ msg, wixWindow, width = 445, height = 250 }) => {
  const experiments = await getExperiments()
  if (experiments.enabled('specs.cx.FormBuilderServerErrorModal')) {
    wixWindow.openModal(getErrorModalUrl(msg), { width, height })
  }
}

export const initAppForPage = ({ instance, url }, { links }) => {
  linksUtil = links
  initInstance = instance
  viewerAppUrl = url

  wixData = createWixData(self.elementorySupport)

  resolveExperiments(new Experiments({ baseUrl: 'https://www.wix.com', scope: 'wix-form-builder' }))
  return Promise.resolve()
}

const parseInstance = instance => JSON.parse(atob(instance.split('.')[1]))

const getVisitorId = () => initInstance && parseInstance(initInstance).aid

const getMsid = () => initInstance && parseInstance(initInstance).metaSiteId

const getFormName = $w => {
  const form = $w(`@${ROLE_FORM}`)
  return {
    form_comp_id: form.uniqueId,
    form_name: form.connectionConfig.formName,
  }
}

const getFormParamsForBi = ($w, fields, wixLocation) => ({
  visitor_id: getVisitorId(),
  num_of_attachments: getAttachmentsCount(fields),
  form_url: wixLocation.url || '',
  ...getFormName($w),
})

const getSubmitErrorParamsForBi = ({ $w, fields, wixLocation, reason, reason_body }) => ({
  reason,
  reason_body,
  ...getFormParamsForBi($w, fields, wixLocation),
})

const getAttachmentsCount = fields =>
  _.filter(fields, field => isUploadButton(field) && field.value.length > 0).length

const getFieldValidity = fields => {
  const errorOrder = [
    'valueMissing',
    'fileNotUploaded',
    'typeMismatch',
    'patternMismatch',
    'rangeOverflow',
    'rangeUnderflow',
    'stepMismatch',
    'tooLong',
    'tooShort',
    'badInput',
    'customError',
  ]
  const errorType = _.find(errorOrder, error => _.some(fields, `validity.${error}`))
  const field = _.find(fields, field => field.validity[errorType])
  return `${errorType} : ${_.get(field, 'connectionConfig.fieldType')}`
}

const isTemplate = wixLocation => !wixLocation.baseUrl

const showFormError = (message, errorMessage) => {
  if (!_.get(message, 'html')) {
    return
  }
  const colorRegExp = /color: ?[^;"]+/
  let htmlErrorMessage = errorMessage
  if (message.html.indexOf(colorRegExp) === -1) {
    htmlErrorMessage = `<span style="color: ${ERROR_COLOR}">${htmlErrorMessage}</span>`
  }
  message.html = message.html
    .replace(colorRegExp, `color: ${ERROR_COLOR}`)
    .replace(new RegExp(`>${escapeRegExp(innerText(message.html))}`), `>${htmlErrorMessage}`)
  message.show()
}

const onSubmit = async ({ $w, collectionId, $message = {}, wixLocation, wixWindow }, strategy) => {
  let fields = []
  let $submitButton
  const experiments = await getExperiments()

  try {
    biLogger.log({
      evid: viewerEvents.USER_CLICKS_SUBMIT,
      ...getFormParamsForBi($w, fields, wixLocation),
    })

    $submitButton = getSubmitButton($w)
    $submitButton.disable()

    fields = getFields({ $w, roles: FIELDS_ROLES })

    if (!validateFields({ fields, strategy })) {
      biLogger.log({
        evid: viewerEvents.SUBMISSION_FAILURE,
        ...getSubmitErrorParamsForBi({
          $w,
          fields,
          wixLocation,
          reason: 'field validity',
          reason_body: getFieldValidity(fields),
        }),
      })

      $submitButton.enable()

      return false
    }

    let attachments, serverRequest

    if (!isTemplate(wixLocation)) {
      attachments = await getAttachments(fields)
      serverRequest = await sendFieldsToServer({ strategy, attachments, fields })
    }

    if (serverRequest && serverRequest.ok) {
      if (collectionId) {
        const fieldsToInsert = getCollectionFields({ fields, attachments })
        await sendFieldsToWixData({ wixData, collectionId, fieldsToInsert })

        biLogger.log({
          evid: viewerEvents.SENT_TO_WIXDATA_SERVER_SUCCESS,
          ...getFormParamsForBi($w, fields, wixLocation),
        })
      }

      // this event should be after all server requests (wix forms + wix data)
      biLogger.log({
        evid: viewerEvents.SUBMISSION_SUCCESS,
        ...getFormParamsForBi($w, fields, wixLocation),
      })

      resetFields(fields)

      if (experiments.enabled('specs.cx.FormBuilderSubmitRedirectOptions')) {
        strategy.postSubmission()
      } else {
        strategy.postSubmissionOld()
      }
    } else {
      displayErrorModal({ wixWindow, msg: ERRORS.SUBMISSION })

      biLogger.log({
        evid: viewerEvents.SUBMISSION_FAILURE,
        ...getSubmitErrorParamsForBi({
          $w,
          fields,
          wixLocation,
          reason: 'server error',
          reason_body: _.get(serverRequest, 'status'),
        }),
      })
    }

    $submitButton.enable()
  } catch (err) {
    if ($submitButton) {
      $submitButton.enable()
    }

    const submitErrorType = getSubmitErrorType(err)

    biLogger.log({
      evid: viewerEvents.SUBMISSION_FAILURE,
      ...getSubmitErrorParamsForBi({
        $w,
        fields,
        wixLocation,
        reason: submitErrorType,
        reason_body: err.name,
      }),
    })

    console.error(`form submit failed with: ${err}`) //eslint-disable-line no-console

    showFormError($message, `Something went wrong. Please try again later`) // FIXME - eager i18n for error message

    Raven.captureException(err, {
      tags: { submit_error_type: submitErrorType },
      extra: {
        error: serializeError(err),
      },
    })
  }
}

const registerSubmitButtonIfExists = ($w, submitArgs) => {
  const $submitButton = getSubmitButton($w, false)

  const strategy = _.find(strategies, s => s.isEnabled($w))
  if (!$submitButton || !strategy) {
    return
  }
  const strategyImp = new strategy(submitArgs, initInstance, linksUtil)
  $submitButton.onClick(Raven.wrap(() => onSubmit(submitArgs, strategyImp)))
  // FIXME - Check why Raven.wrap does not catch exception (replaced throw err with captureException to overcome this for now)
}

const pageReadyImpl = ($w, { window: wixWindow, location: wixLocation, user: wixUsers }) => {
  try {
    Raven.setUserContext({ id: `${wixLocation.url}` })
  } catch (err) {
    Raven.captureException(err)
  }
  if (!$w(`@${ROLE_FORM}`).length) {
    return
  }
  biLogger = initBiLogger({ defaults: { msid: getMsid() } })
  const { collectionId, secondsToResetForm, successActionType, successLinkValue,submitOptionsUploadedObject } = $w(
    `@${ROLE_FORM}`
  ).connectionConfig
  const $message: any = $w(`@${ROLE_MESSAGE}`)
  const successMessage = _.get($message, 'html')
  let submitArgs: any = {
    $w,
    collectionId,
    successMessage,
    secondsToResetForm,
    successActionType,
    successLinkValue,
    submitOptionsUploadedObject,
    wixLocation,
    wixWindow,
    wixUsers,
  }

  if (_.get($message, 'hide')) {
    $message.hide()
    submitArgs = { ...submitArgs, $message }
  }
  registerSubmitButtonIfExists($w, submitArgs)
}

export const createControllers = Raven.wrap(controllerConfigs => {
  return controllerConfigs.map(() =>
    Promise.resolve({
      pageReady: Raven.wrap(pageReadyImpl),
    })
  )
})
