export enum LinkPanelTypes {
  NONE = 'none',
  PAGE = 'PageLink',
  LIGHTBOX = 'PageLink',
  EXTERNAL_LINK = 'ExternalLink',
  DOCUMENT_LINK = 'DocumentLink',
}

export enum LinkTypes {
  NONE = 'none',
  PAGE = 'page',
  LIGHTBOX = 'lightbox',
  EXTERNAL_LINK = 'ExternalLink',
  DOCUMENT_LINK = 'DocumentLink',
}

export enum mediaTypes {
  DOCUMENT = 'DOCUMENT',
  IMAGE = 'IMAGE',
}

export enum SettingsTabs {
  GENERAL = 'general',
  SUBMISSION = 'submission',
}

export enum Urls {
  SEND_FORMS_FEEDBACK_URL = 'https://www.wixformsfeedback.com/',
}
